import React from 'react'
import tw from 'twin.macro'
import { samlSsoImage } from '../../../cdn_images/campaign'
import TwoColumnGrid from '../../../components/campaign/grid/two_column_grid'
import Hero from '../../../components/campaign/hero'
import HubspotForm from '../../../components/forms/hupspot_form'
import Layout from '../../../components/layout'

const formConfigs = [
  {
    formId: '416019d4-3cd4-4c22-b3c5-cca6cbbe1676',
    locale: 'en-us',
    portalId: '7005624',
    region: 'na1',
  },
  {
    formId: '157041e9-cd5f-43b7-a630-f6384b06201e',
    locale: 'fr-fr',
    portalId: '7005624',
    region: 'na1',
  },
]

const formStyle = {
  form: tw`mb-6 mt-12 space-y-6`,
  input: tw`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 sm:text-sm`,
  button: tw`w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-purple-700 hover:bg-purple-600 focus:outline-none cursor-pointer`,
  errorMsg: tw`mt-2 text-xs text-red-600`,
  email: tw`h-12 w-full`,
}

const SamlSsoCampaignPage = ({ pageContext: { data, localePath, lang } }: any) => {
  if (!data) return null

  const config = formConfigs.filter((config) => config.locale === lang)

  return (
    <Layout
      helmetTitle="SAML SSO"
      helmetDescription="Companies that try Cryptr improve their conversions, reduce churn... All the features to improve your end-users experience"
      helmetImage={'/metatag_image_cryptr.png'}
      helmetUrl={`https://www.cryptr.co/${localePath}/`}
    >
      <Hero logosHeadline={data.logos_headline.raw} showLogos>
        <TwoColumnGrid
          eyebrow={data.eyebrow}
          title={data.title.raw}
          description={data.description.raw}
          valuePropositions={data.body[0].items}
          illustration={samlSsoImage.illustration}
        >
          <HubspotForm config={config[0]} style={formStyle} />
        </TwoColumnGrid>
      </Hero>
    </Layout>
  )
}

export default SamlSsoCampaignPage
