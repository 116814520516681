import styled from '@emotion/styled'
import tw from 'twin.macro'

export const HeroGrid = styled.div`
  ${tw`
    lg:grid
    lg:grid-cols-12
    lg:gap-8
    mt-24
  `}
`

export const Content = styled.div`
  ${tw`
    sm:pr-6
    sm:text-center
    md:max-w-2xl
    md:mx-auto
    lg:col-span-6
    lg:text-left
    lg:flex
    lg:items-center
  `}
`

export const IllustrationWrapper = styled.div`
  ${tw`  
    flex 
    justify-center
    lg:block
    lg:col-span-6
    mt-16
    sm:mt-24
    lg:mt-0
  `}
`
