import React, { ReactNode } from 'react'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { HeroGrid, IllustrationWrapper, Content } from './style'
import { HeroDescription, HeroEyebrow, HeroTitle } from '../../hero/style'
import SectionValuePropositions from '../../../section_value_propositions'
import SectionWrapper from '../../../sectionWrapper'

type ColumnGrid = {
  children: ReactNode
  description: RichTextBlock[]
  eyebrow: string | null
  illustration: any
  title: RichTextBlock[]
  valuePropositions: ValueProposition[]
}

type ValueProposition = {
  text: string
}

const TwoColumnGrid = ({
  children,
  description,
  eyebrow,
  illustration,
  title,
  valuePropositions,
}: ColumnGrid) => {
  return (
    <>
      <HeroGrid>
        <Content>
          <div>
            <HeroEyebrow>{eyebrow}</HeroEyebrow>
            <HeroTitle>{RichText.render(title)}</HeroTitle>
            <HeroDescription>{RichText.render(description)}</HeroDescription>
            {children}
          </div>
        </Content>
        <IllustrationWrapper>{illustration}</IllustrationWrapper>
      </HeroGrid>
      <SectionWrapper disabledPadding="bottom">
        <SectionValuePropositions valuePropositions={valuePropositions} />
      </SectionWrapper>
    </>
  )
}

export default TwoColumnGrid
